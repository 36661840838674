import * as userAction from "./userAction";

export const userFeatureKey = "userInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  users: [],
  admins: [],
  userDetails: {},
  userInfo: {},
  adminDetails: {},
  isUserUpdate: false,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {

    case userAction.GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case userAction.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: payload.data,
      };

    case userAction.GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        userInfo: null
      };
    
    case userAction.LOGOUT:
      return {
        ...state,
        userInfo: null
      };

    // get users list

    case userAction.GET_USER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userAction.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        users: payload.data,
      };
    case userAction.GET_USER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    // get Admins list

    case userAction.GET_ADMIN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userAction.GET_ADMIN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        admins: payload.data,
      };
    case userAction.GET_ADMIN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // get user details

    case userAction.GET_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userAction.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: payload.data,
      };
    case userAction.GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // case "IS_USER_UPDATE":
    //   return {
    //     ...state,
    //     isUserUpdate: false,
    //   };

    // make user Inactive

    case userAction.MAKE_USER_INACTIVE_REQUEST:
      return {
        ...state,
        loading: true,
        isUserUpdate: false,
      };
    case userAction.MAKE_USER_INACTIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: payload.data,
        isUserUpdate: true,
      };
    case userAction.MAKE_USER_INACTIVE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        isUserUpdate: false,
      };

    case userAction.MAKE_USER_INPREMIUM_REQUEST:
      return {
        ...state,
        loading: true,
        isUserUpdate: false,
      };
    case userAction.MAKE_USER_INPREMIUM_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: payload.data,
        isUserUpdate: true,
      };
    case userAction.MAKE_USER_INPREMIUM_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        isUserUpdate: false,
      };

    case userAction.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userAction.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userAction.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
