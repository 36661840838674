import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./App.scss";
import * as userAction from "../redux/user/userAction";
import { useDispatch, useSelector } from "react-redux";
import AppRoutes from "./AppRoutes";
import Navbar from "./views/shared/Navbar";
import Sidebar from "./views/shared/Sidebar";
// import Footer from './shared/Footer';

const App = () => {
  const location = useLocation();
  const [isFullPageLayout, setIsFullPageLayout] = useState(false);
  const userInfo = useSelector((state) => state.userInfo.userInfo);

  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      if (!userInfo?._id) {
        dispatch(userAction.getUser());
      }
    }
  }, [location, token]);

  const onRouteChanged = () => {
    const body = document.querySelector("body");
    if (location.pathname === "/layout/RtlLayout") {
      body.classList.add("rtl");
    } else {
      body.classList.remove("rtl");
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ["/login", "/404", "/500"];
    const isFullPage = fullPageLayoutRoutes.includes(location.pathname);
    setIsFullPageLayout(isFullPage);
  };

const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    onRouteChanged();
  }, [location]);

  useEffect(() => {
    if (userInfo?.role === 'admin') {
      navigate("/hcp/list");
    } else if (userInfo?.role === 'HCP'){
      navigate(`/patient/${userInfo._id}/list`);
    }
  }, [userInfo])
  
  let navbarComponent = !isFullPageLayout ? <Navbar /> : "";
  let sidebarComponent = !isFullPageLayout ? <Sidebar /> : "";
  // let footerComponent = !isFullPageLayout ? <Footer /> : '';

  return (
    <div className="container-scroller">
      {navbarComponent}
      <div
        className={`container-fluid page-body-wrapper ${
          location.pathname === "/login"
            ? "full-page-wrapper"
            : location.pathname === "/404"
            ? "full-page-wrapper"
            : location.pathname === "/500"
            ? "full-page-wrapper"
            : ""
        }`}
      >
        {sidebarComponent}
        <div className="main-panel">
          <div className="content-wrapper">
            <AppRoutes />
          </div>
          {/* {footerComponent} */}
        </div>
      </div>
    </div>
  );
};

export default App;
