import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";

import Spinner from "../app/views/shared/Spinner";

const List = lazy(() => import("./views/pages/List"));

const User = lazy(() => import("./views/pages/user/User"));
const Patient = lazy(() => import("./views/pages/user/Patient"));
const ChangePassword = lazy(() => import("./views/pages/changePassword/ChangePassword"));

const Error404 = lazy(() => import("./views/pages/error/Error404"));
const Error500 = lazy(() => import("./views/pages/error/Error500"));

const Login = lazy(() => import("./views/pages/auth/Login"));

const AppRoutes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* <Route path="/forget-password" element={<ForgetPassword />} /> */}

        <Route exact path="/hcp/list" element={<List forUser />} />
        <Route exact path="/hcp/:id/detail" element={<User />} />
        <Route exact path="/hcp/:id/patient/:patientId/detail" element={<Patient />} />

        <Route exact path="/patient/:id/list" element={<User />} />
        <Route exact path="/change-password" element={<ChangePassword />} />

        <Route path="/404" element={<Error404 />} />
        <Route path="/500" element={<Error500 />} />
        <Route exact path="*" element={<Error404 />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
