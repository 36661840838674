import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const LOGIN_FOR_ADMIN_REQUEST = "LOGIN_FOR_ADMIN_REQUEST";
export const LOGIN_FOR_ADMIN_SUCCESS = "LOGIN_FOR_ADMIN_SUCCESS";
export const LOGIN_FOR_ADMIN_FAILURE = "LOGIN_FOR_ADMIN_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const login = (data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_FOR_ADMIN_REQUEST });
      const url = `${BASE_URL}/admin/login`;
      const response = await axios.post(url, data);
      dispatch({ type: LOGIN_FOR_ADMIN_SUCCESS, payload: response.data });
      if (response.data?.data?.role === 'admin') {
        navigate("/hcp/list");
      } else {
        navigate(`/patient/${response.data?.data._id}/list`);
      }
      toast.success("Login Successfull");
    } catch (error) {
      console.error(error);
      dispatch({ type: LOGIN_FOR_ADMIN_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};