import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import NavLink from "../components/NavLink";

const Sidebar = () => {
  const location = useLocation();
  const [menuState, setMenuState] = useState({});

  const user = useSelector((state) => state.loginInfo.userDetails);
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  // const userInfo = useSelector((state) => state.userInfo.userInfo);

  const onRouteChanged = () => {
    document.querySelector("#sidebar").classList.remove("active");
    setMenuState({});
  };

  const sidebarData = [
    { iconClass: "mdi-account-multiple", title: "HCP List", url: "/hcp/list" },
    { iconClass: "mdi mdi-lock", title: "Change Password", url: "/change-password" }
  ];

  const sidebarHCPData = [
    { iconClass: "mdi-post", title: "Patients", url: `/patient/${user._id}/list` },
  ];

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };

  useEffect(() => {
    onRouteChanged();
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });

    return () => {
      // Clean up event listeners if needed
    };
  }, [location]);

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        {
          user && user?._id ? (
            <>
              {
                (user?.role === "HCP") && (
                  <>
                    {sidebarHCPData.map((data) => {
                      return (
                        <NavLink
                          isPathActive={isPathActive}
                          url={data.url}
                          iconClass={data.iconClass}
                          title={data.title}
                        />
                      );
                    })}
                  </>
                ) 
              } 
              {
                (user?.role === "admin") && (
                  <>
                    {sidebarData.map((data) => {
                      return (
                        <NavLink
                          isPathActive={isPathActive}
                          url={data.url}
                          iconClass={data.iconClass}
                          title={data.title}
                        />
                      );
                    })}
                  </>
                )
              }
            </>
          ) : (
            <>
              {
                (user?.role === "HCP" || userInfo?.role === 'HCP') && (
                  <>
                    {sidebarHCPData.map((data) => {
                      return (
                        <NavLink
                          isPathActive={isPathActive}
                          url={data.url}
                          iconClass={data.iconClass}
                          title={data.title}
                        />
                      );
                    })}
                  </>
                ) 
              } 
              {
                (user?.role === "admin" || userInfo?.role === 'admin') && (
                  <>
                    {sidebarData.map((data) => {
                      return (
                        <NavLink
                          isPathActive={isPathActive}
                          url={data.url}
                          iconClass={data.iconClass}
                          title={data.title}
                        />
                      );
                    })}
                  </>
                )
              }
            </>
          )
        }
      </ul>
    </nav>
  );
};

export default Sidebar;
